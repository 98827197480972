const priceTypeMap = {
  1: '元/吨',
  2: '元/方',
  3: '元/件'
};

const WeigthEnum = {
  3: {
    name: '克',
    limit: 1
  },
  2: {
    name: '千克',
    limit: 1000
  },
  1: {
    name: '吨',
    limit: 1000000
  }
};

const showNumberBySets = (setType = 'weight', data) => {
  // TODO: 根据后端回传type显示不同单位
  if (setType === 'volume') {
    const vol = ((data.publishType === 0 ? data.driverShippingBillGoodsVO.volume : data.volumeLimit) / 1000).toFixed(3);
    return parseFloat(vol) || '--';
  } else {
    return {
      weight: (
        (data.publishType === 0 ? data.driverShippingBillGoodsVO.actualWeight : data.weightLimit) /
        WeigthEnum[data.companyWeightUnit || 1].limit
      ).toFixed(3), // 根据单位还要进行处理
      weightUnit: WeigthEnum[data.companyWeightUnit || 1].name // 需要根据后端回传单位做回显
    };
  }
};

export { priceTypeMap, showNumberBySets };
