<template>
  <div class="source px-16 bg-color pb-50 pt-16">
    <div v-if="dataList.length">
      <div v-for="(item, index) in dataList" :key="index">
        <Scard :cardData="item" />
      </div>
    </div>
    <Empty v-else imgWidth="0.84rem" imgHeight="0.78rem" :image="emptyImg" description="暂无运单" />
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api';
import Scard from './components/card';
// import { useRouterQuery } from '@hook/useRouterQuery';
import { apiGetSourceList } from '@/api/source';

export default defineComponent({
  name: 'Source',
  components: {
    Scard
  },
  setup() {
    const dataList = ref([]);
    const emptyImg = require('@/assets/htkb.png');
    // const query = useRouterQuery();

    apiGetSourceList({
      param: ''
      // userLat: query.userLat || 120.2,
      // userLng: query.userLng || 30.3
    }).then(res => {
      dataList.value = res.data;
    });
    return {
      emptyImg,
      dataList
    };
  }
});
</script>
<style lang="less">
.source {
  min-height: 100%;
}
</style>
