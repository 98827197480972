<template>
  <div class="source-card bda-4 pa-16 d-flex flex-column mb-16" @click="() => handleJump(cardData)">
    <div class="d-flex justify-space-between mb-16">
      <div v-if="cardData.appDisplayShipperInfo === 1" class="d-flex">
        <Icon name="gs" />
        <span class="ml-8">{{ cardData.shippingBillShipperInfoDTO.shipperName }}</span>
      </div>
      <div class="font-12 text_muted">距我{{ (cardData.addressInfoDTO.senderDistance / 1000).toFixed(2) }}km</div>
    </div>
    <div class="flex justify-start items-center mb-16 text-14 text-secondary " v-if="cardData.routeName">
      <img
        style="width: 0.12rem; height: 0.12rem"
        src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_app/xianlumingcheng.png"
      />
      <div class="ml-8 text-truncate ...">
        {{ cardData.routeName }}
      </div>
    </div>
    <div class="d-flex justify-space-between mb-10">
      <div class="flex-1">
        <div class="font-18 text-center mb-4 fw-700">
          {{ cardData.addressInfoDTO.senderCityName }}
        </div>
        <div class="text-center text_secondary">
          {{ cardData.addressInfoDTO.senderDistrictName }}
        </div>
      </div>
      <div class="font-12 pt-4">
        <div class="text-truncate text-center route-name">
          {{ cardData.routeName }}
        </div>
        <div>
          <Icon svgClass="icon-class" name="qdzd" />
        </div>
        <div class="text_primary text-center">
          {{ (cardData.driverShippingBillGoodsVO.actualWeight / 1000000).toFixed(2) }}
          {{ cardData.driverShippingBillGoodsVO.weightUnitStr }}
        </div>
      </div>
      <div class="flex-1">
        <div class="font-18 text-center mb-4 fw-700">
          {{ cardData.addressInfoDTO.receiverDistrictName }}
        </div>
        <div class="text-center text_secondary">
          {{ cardData.addressInfoDTO.receiverCityName }}
        </div>
      </div>
    </div>
    <div class="bdt d-flex pt-16 font-14 card-footer justify-space-between">
      <div class="d-flex pt-8">
        <div class="d-flex" v-if="cardData.appShowPrice !== 0">
          <span class="text_warning">￥</span>
          <span class="text_warning mr-8 font-18 fw-700">{{ cardData.pricePlanDTO.transportPrice / 100 }}</span>
          <span class="text_muted mr-8">{{ cardData.pricePlanDTO.priceUnitTypeStr }}</span>
        </div>
        <a @click.stop="stopDefineFun" :href="`tel:${cardData.addressInfoDTO.senderPhone}`">
          <Icon name="dh" svgClass="call-phone" />
        </a>
      </div>
      <div>
        <Button type="info" @click.stop="handleReveice">立即接单</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { priceTypeMap } from '../constants/index';
import { useRouter } from '@/utils/compsitionHack';
import { Button, Dialog } from 'vant';
import { PageEnum } from '@/enums/pageEnum';
import { verifyBlackAPi } from '@/api/mine';
import { useUserStore } from '@/store/user';

export default defineComponent({
  props: {
    cardData: Object,
    default: () => {}
  },
  components: {
    Button
  },
  setup(props) {
    const router = useRouter();
    const userStore = useUserStore();
    const handleReveice = async () => {
      try {
        const resDriverBlack = await verifyBlackAPi({
          type: 0,
          infoKeys: [userStore?.driverAuthVO?.idCard]
        });
        if (resDriverBlack?.data?.[0]?.isBlack) {
          Dialog.confirm({
            message: '您在当前平台黑名单中，限制开展新业务',
            showConfirmButton: false,
            cancelButtonText: '确定'
          });
          return;
        }
        router.push({
          path: PageEnum.SOURCE_INFORMATION,
          query: {
            id: props.cardData.id
          }
        });
      } catch (e) {
        //
      }
    };
    const stopDefineFun = () => {};

    const handleJump = item => {
      router.push({
        path: PageEnum.SOURCE_DETAIL,
        query: {
          id: item.id
        }
      });
    };
    return {
      priceTypeMap,
      handleReveice,
      stopDefineFun,
      handleJump
    };
  }
});
</script>
<style lang="less">
.source-card {
  width: 100%;
  min-height: 1.96rem;
  background: white;
  .flex-1 {
    flex: 1;
  }
  .route-name {
    width: 0.88rem;
  }
  .card-footer {
    line-height: 0.24rem;
  }
  .icon-class {
    width: 0.88rem;
  }
  .call-phone {
    width: 0.24rem;
    height: 0.24rem;
  }
}
</style>
