import { http } from '../utils/http';

const publicPath = '/driver-center';

export const apiGetSourceList = async params => {
  return await http.post(`/api${publicPath}/driver/shipping/bill/search`, params, false);
};

export const apiGetSourceDetail = async params => {
  return await http.post(`/api${publicPath}/driver/shipping/bill/detail`, params, false);
};

export const apiTakingOrder = async params => {
  return await http.post(`/api${publicPath}/driver/shipping/bill/order/taking`, params, false);
};

export const apiCheckOrder = async params => {
  return await http.post(`/api${publicPath}/driver/shipping/bill/searchUserHasOrder`, params, false);
};
